<template>
    <div class="container">
        <h1>Payment completed</h1>
        <p>Emails sent to both customer and office.</p>
    </div>
</template>

<script setup lang="ts">

import { useStripeSendPaymentConfirmationEmailsMutation } from '../../iris/src/queries'

const route = useRoute()

const { mutate } = useStripeSendPaymentConfirmationEmailsMutation({})

type RedirectStatus = 'failed' | 'succeeded'

const redirectStatus = computed<RedirectStatus>(() => {
    return route.query.redirect_status! as RedirectStatus
})

onMounted(() => {
    if (redirectStatus.value === 'succeeded') {
        return mutate({
            amount: Number(route.query.amount),
            email: route.query.email,
            firstName: route.query.firstName,
            lastName: route.query.lastName,
            paymentIntentId: route.query.payment_intent,
            postcode: route.query.postCode,
            subscriberId: Number(route.query.subscriberId)
        })
    }
})

</script>